import { render, staticRenderFns } from "./element-topbar.component.vue?vue&type=template&id=2a93a46a&scoped=true&"
import script from "./element-topbar.component.vue?vue&type=script&lang=js&"
export * from "./element-topbar.component.vue?vue&type=script&lang=js&"
import style0 from "./element-topbar.component.vue?vue&type=style&index=0&id=2a93a46a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a93a46a",
  null
  
)

export default component.exports