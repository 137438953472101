<template>
  <div
    @mousedown="rootEvent($event)"
    @mouseup="rootEvent($event)"
    class="page-editor page-template-editor"
  >
    <loading
      :active="$wait.is('savingProject')"
      :is-full-page="false"
    ></loading>
    <topbar :projectId="projectId" ref="topbar"></topbar>

    <v-style>
      .{{ "graphproject-canvas" }}:before { background:
      {{ this.canvas.backgroundOverlayColor }}; }
    </v-style>

    <div class="editor-wrap">
      <element-topbar @deleteElement="deleteElement"></element-topbar>

      <editor-sidebar :isCartesianChart="isCartesianChart"></editor-sidebar>

      <div class="main-content">
        <div class="graphproject-wrap">
          <!--  graphproject-canvas is the element which we export-->
          <article
            id="graphproject-canvas"
            class="graphproject-canvas"
            :style="{
              width: this.canvas.width + 'px',
              height: this.canvas.height + 'px',
              background: `${this.canvas.background} ${this.canvas.backgroundPosition} / ${this.canvas.backgroundSize} ${this.canvas.backgroundRepeat}`,
            }"
          >
            <canvas-elements
              :initialElements="initialElements"
            ></canvas-elements>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "./sidebar/sidebar.component";
import topbar from "@/components/common/topbar/topbar.component";
import elementTopBar from "./element-topbar/element-topbar.component";
import draggable from "@/components/common/draggable-moveable/draggable-moveable.component.vue";
import draggableResizable from "@/components/common/draggable-resizable/draggable-resizable.component.vue";
import canvasElements from "./canvas-elements/canvas-elements.component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    topbar,
    "element-topbar": elementTopBar,
    "editor-sidebar": sidebar,
    draggable,
    "draggable-resizable": draggableResizable,
    "canvas-elements": canvasElements,
  },
  data() {
    return {
      templateId:
        this.$route.query && this.$route.query.template
          ? window.atob(this.$route.query.template)
          : null,
      projectId: this.$route.params.projectId,
      initialElements: [],
      overlayColor: "",
    };
  },
  watch: {
    "$route.params.projectId": {
      handler: function(projectId, oldProjectId) {
        this.projectId = this.$route.params.projectId;
        if (projectId === "new" && typeof oldProjectId !== "undefined") {
          this.resetEverything()
            .then(() => {
              this.mountProject();
            })
            .catch((e) => {
              console.error(e);
            });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted: function() {
    this.resetEverything()
      .then(() => {
        this.mountProject();
      })
      .catch((e) => {
        console.error(e);
      });
    document.addEventListener("keyup", this.keyUp);
    document.addEventListener("keydown", this.keyDown);
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAuthenticated && this.isDirty) {
      this.$confirm("Changes you have made will not be saved", "Warning", {
        confirmButtonText: "Leave",
        cancelButtonText: "Stay on page",
        type: "warning",
      })
        .then(() => {
          next();
        })
        .catch(() => {
          return;
        });
    } else {
      next();
    }
  },
  beforeDestroy: function() {
    const me = this;
    this.resetEverything();
    document.removeEventListener("keyup", this.keyUp);
    document.removeEventListener("keydown", this.keyDOwn);
  },
  computed: {
    ...mapGetters("elementsModule", ["canvas", "isDirty"]),
    ...mapActions("projectsModule", ["getProjectById", "resetCurrentProject"]),
    ...mapActions("elementsModule", [
      "changeCanvasProperties",
      "changeActiveElement",
      "changeActiveChart",
      "deleteLayer",
      "resetEditor",
    ]),
  },
  methods: {
    resetEverything() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("elementsModule/resetEditor")
          .then(() => {
            this.$store.dispatch("projectsModule/resetCurrentProject");
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    preventNav(event) {
      if (!this.isDirty) return;
      event.preventDefault();
      event.returnValue = "";
    },
    keyUp(e) {
      if (this.activeElementId && (e.keyCode === 8 || e.keyCode === 46)) {
        this.deleteElement();
      }
    },
    keyDown(e) {
      var key = undefined;
      var possible = [e.key, e.keyIdentifier, e.keyCode, e.which];

      while (key === undefined && possible.length > 0) {
        key = possible.pop();
      }

      if (
        key &&
        (key == "115" || key == "83") &&
        (e.ctrlKey || e.metaKey) &&
        !e.altKey
      ) {
        this.$refs.topbar.saveMyProject();
        e.preventDefault();

        return false;
      }
    },
    mountProject: function() {
      const me = this;
      let pid = null;
      if (this.projectId !== "new") {
        pid = this.projectId;
      } else {
        if (this.$route.query && this.$route.query.template) {
          // get new template ID if changed
          this.templateId = window.atob(this.$route.query.template);
          pid = this.templateId;
        }
      }
      if (pid) {
        this.getExistingProjectById(pid);
      }
    },
    async getExistingProjectById(id) {
      try {
        const project = await this.$store.dispatch(
          "projectsModule/getProjectById",
          {
            id,
            is_template: this.$route.query && this.$route.query.template,
          }
        );
        this.initialElements = project.elements;

        await this.$store.dispatch(
          "elementsModule/changeCanvasProperties",
          project.canvas_properties
        );
      } catch (e) {
        return e;
      }
    },
    rootEvent(e) {
      const target = e.target;

      // false positives
      if (
        target.classList.contains("moveable-control") ||
        target.classList.contains("vdr-stick") ||
        target.closest(".drag-resize") ||
        target.closest(".element-topbar") ||
        target.closest(".sidebar")
      )
        return;

      if (
        e.type === "mousedown" &&
        !target.isContentEditable &&
        this.activeElementId === target.id &&
        this.activeElementType === "section"
      ) {
        // on mousedown, make the text content-editable true
        target.setAttribute("contenteditable", true);
        target.focus();
      }

      /**
       *
       * IMPORTANT NOTE:
       *
       * Do not use # (id) in any element inside canvas.
       * Otherwise, whenever that element is clicked,
       * it will trigger the activeElement function.
       *
       * If activeElement is triggered without any ID, then all elements are deselected
       */

      if (e.type === "mousedown") {
        if (target.classList.contains("apexcharts-canvas")) {
          this.activateChart(target);
        } else {
          this.activateElement(target, e);
        }
      }
    },
    activateChart(target) {
      const id = target.id.substr(10);
      this.$store
        .dispatch("elementsModule/changeActiveChart", { id })
        .then((response) => {
          this.$store.dispatch("elementsModule/changeActiveElement", {
            id: null,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    activateElement(target, e) {
      this.$store
        .dispatch(
          "elementsModule/changeActiveElement",
          target.id ? { id: target.id, e: e } : { id: null }
        )
        .then((response) => {
          this.$store.dispatch("elementsModule/changeActiveChart", {
            id: null,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    deleteElement: function() {
      const id = this.getID();
      if (this.activeElement && this.activeElementType === "section") {
        if (this.activeElement.getAttribute("contenteditable") === "true") {
          return;
        }
      }

      if (!id || this.activeElementType === "article") return;

      this.$store
        .dispatch("elementsModule/deleteLayer", { id })
        .then((response) => {
          this.$store
            .dispatch("elementsModule/changeActiveChart", { id: null })
            .then((response) => {
              this.$store.dispatch("elementsModule/changeActiveElement", {
                id: null,
              });
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getID: function() {
      let id = null;
      if (this.activeElementId) {
        id = this.activeElementId;
      } else if (this.activeChartId) {
        id = this.activeChartId;
      }
      return id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "editor.component.scss";
</style>
