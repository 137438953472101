<template>
  <div class="topbar topbar-38c2d83b">
    <div class="topbar-inner">
      <div class="topbar-left">
        <div class="logo">
          <img
            @click="gotoHome()"
            class="img-logo"
            :src="`${publicPath}assets/apexcharts-graphmaker-logo-white.svg`"
          />
        </div>
        <div class="filename">
          <el-input
            class="project-name"
            @blur="saveMyProject"
            v-model="docName"
          ></el-input>
        </div>
      </div>
      <el-menu class="el-menu" mode="horizontal">
        <div class="menu-left">
          <div class="menu-section d-flex">
            <span
              :class="{ 'btn-save': true, dirty: isDirty }"
              @click="saveMyProject"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 0 24 24"
                width="20"
                fill="#fff"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"
                />
              </svg>
              <span>Save</span>
            </span>

            <span
              :class="{ 'btn-resize': true }"
              @click="toggleResizeDialog(true)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 0 24 24"
                width="20"
                fill="#fff"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
                />
              </svg>
              <span>Resize</span>
            </span>
          </div>
        </div>
        <div class="menu-right">
          <div class="menu-section ml2">
            <el-button
              @click="toggleSettingsDialog(true)"
              v-if="isAuthenticated && isAdmin"
              type="text"
              size="small"
            >
              <i class="el-icon-s-tools"></i>
            </el-button>
            <el-dropdown @command="downloadProject" trigger="click">
              <el-button type="text" size="small">
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="#fff"
                    d="M 15 4 L 15 20.5625 L 9.71875 15.28125 L 8.28125 16.71875 L 15.28125 23.71875 L 16 24.40625 L 16.71875 23.71875 L 23.71875 16.71875 L 22.28125 15.28125 L 17 20.5625 L 17 4 Z M 7 26 L 7 28 L 25 28 L 25 26 Z"
                  />
                </svg>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="SVG">Download SVG</el-dropdown-item>
                <el-dropdown-item command="PNG">Download PNG</el-dropdown-item>
                <el-dropdown-item command="JPG">Download JPEG</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              @click="toggleEmbedDialog(true)"
              class="ml3"
              type="text"
              size="small"
            >
              <i class="el-icon-share"></i>
            </el-button>
          </div>

          <el-button
            v-if="!isAuthenticated"
            type="text"
            @click="openLoginBox"
            icon="fas fa-edit"
            class="ml1"
          >
            <i class="el-icon-user-solid"></i>
          </el-button>
          <dropdown-account class="ml1" v-else></dropdown-account>
        </div>
      </el-menu>
    </div>

    <el-dialog
      title="Resize canvas"
      :visible.sync="resizeDialog"
      :append-to-body="true"
      width="30%"
    >
      <div class="dialog-resize ml3">
        <el-form class="dialog-resize-form" @submit.native.prevent ref="form">
          <el-form-item label="Width">
            <el-input
              @keyup.enter.native="resizeCanvas"
              placeholder="Width"
              v-model="width"
            ></el-input>
          </el-form-item>
          <el-form-item label="Height">
            <el-input
              @keyup.enter.native="resizeCanvas"
              placeholder="Height"
              v-model="height"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resizeCanvas">OK</el-button>
        <el-button @click="toggleResizeDialog(false)">Cancel</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Embed"
      :visible.sync="embedDialog"
      :append-to-body="true"
      width="40%"
    >
      <div class="dialog-embed ml3">
        <el-form class="dialog-embed-form" @submit.native.prevent ref="form">
          <el-form-item label="Embed URL">
            <el-input
              placeholder="Embed URL"
              v-model="embedURL"
              @focus="$event.target.select()"
            ></el-input>
          </el-form-item>
          <el-button
            type="success"
            @click="copyURL"
            icon="el-icon-document-copy"
          >
            Copy
          </el-button>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toggleEmbedDialog(false)"
          >Close</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Project Settings"
      :visible.sync="settingsDialog"
      width="30%"
      :append-to-body="true"
    >
      <div class="settings-dialog">
        <el-checkbox
          size="small"
          class="mt2 mb3 d-block chk-public "
          v-model="isPublicTemplate"
          >PUBLIC TEMPLATE</el-checkbox
        >
        <el-checkbox
          size="small"
          class="mt2 mb3 d-block chk-public mr3"
          v-model="isPremiumTemplate"
          >PRO TEMPLATE</el-checkbox
        >

        <el-input
          placeholder="Enter URL for this template"
          v-model="templateUrl"
        ></el-input>

        <span slot="footer" class="dialog-footer">
          <el-button class="mt3" type="primary" @click="saveMyProject"
            >OK</el-button
          >
          <el-button @click="toggleSettingsDialog(false)">Cancel</el-button>
        </span>
      </div>
    </el-dialog>

    <login-popup
      @onCloseLogin="onLoginBoxClosed"
      @saveAfterLogin="saveMyProject"
      v-if="showLoginBox"
    ></login-popup>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { mapActions, mapGetters } from "vuex";
import loginPopup from "@/components/common/login-popup/login-popup.component";
import dropdownAccount from "./dropdown-account.component";
import { s3upload, loadAWSsdk } from "@/libs/aws-manager";
import ObjectId from "@/libs/object-id";
import config from "@/config";
import { isEmpty } from "@/utils";

export default {
  components: {
    "login-popup": loginPopup,
    "dropdown-account": dropdownAccount,
  },
  props: {
    projectId: {
      type: String,
    },
  },
  data() {
    return {
      isEditMode: this.projectId !== "new",
      showLoginBox: false,
      isPrivate: false,
      isTemplate: false,
      isPublicTemplate: false,
      isPremiumTemplate: false,
      folderId: "all",
      docName: "My Project",
      templateUrl: "",
      meta: {},
      width: 0,
      height: 0,
      settingsDialog: false,
      resizeDialog: false,
      embedDialog: false,
      embedURL: window.location.origin + "/embed/" + this.projectId,
    };
  },

  computed: {
    ...mapGetters("authModule", ["isAuthenticated", "isAdmin", "userInfo"]),
    ...mapGetters("projectsModule", ["currentProject"]),
    ...mapGetters("elementsModule", [
      "activeMoveable",
      "canvas",
      "canvasElements",
      "isDirty",
    ]),
    ...mapActions("elementsModule", ["changeCanvasProperties"]),
    ...mapActions("projectsModule", ["saveProject", "updateProject"]),
  },
  mounted: function() {
    loadAWSsdk();

    this.getExistingCanvasAttrs();
    this.getExistingProjectAttrs();
  },

  watch: {
    $route() {
      this.isEditMode = this.$route.params.projectId !== "new";
    },
    canvas: function() {
      this.getExistingCanvasAttrs();
    },
    currentProject: function() {
      this.getExistingProjectAttrs();
    },
  },
  methods: {
    // ...mapActions("elementsModule", ["undo", "redo"]),
    gotoHome() {
      if (this.isAuthenticated) {
        this.$router.push("/dashboard/saved/all");
      }
    },
    changeDocName(newName) {
      this.$emit("changeProjectName", newName);
    },
    openLoginBox() {
      this.showLoginBox = true;
    },
    onLoginBoxClosed() {
      this.showLoginBox = false;
    },
    async changeCanvasProps(newCanvasProps) {
      await this.$store.dispatch("elementsModule/changeCanvasProperties", {
        ...this.canvas,
        ...newCanvasProps,
      });
      await this.$store.dispatch("elementsModule/changeSaveStateToUnsaved");
      this.toggleResizeDialog(false);
    },
    getExistingCanvasAttrs: function() {
      this.width = this.canvas?.width;
      this.height = this.canvas?.height;
    },
    getExistingProjectAttrs: function() {
      if (!isEmpty(this.currentProject)) {
        this.docName = this.currentProject.file_name;
        this.isPrivate = this.currentProject.is_private;
        this.folderId = this.currentProject.folder_id;
      }

      if (this.isAdmin) {
        // IMPORTANT: these properties should never be updated by a non admin
        this.isPublicTemplate = this.currentProject.is_public_template;
        this.isPremiumTemplate = this.currentProject.is_premium_template;
        this.meta = this.currentProject.meta;
        this.templateUrl = this.currentProject.meta?.template_url;
      }
    },
    toggleResizeDialog: function(val) {
      this.resizeDialog = val;
    },
    toggleSettingsDialog: function(val) {
      this.settingsDialog = val;
    },
    toggleEmbedDialog: function(val) {
      this.embedDialog = val;
    },
    resizeCanvas: function() {
      this.changeCanvasProps({
        width: Number(this.width),
        height: Number(this.height),
      });
    },
    copyURL: function() {
      this.$copyText(this.embedURL).then(() => {
        this.$message({
          type: "success",
          message: "URL Copied",
        });
      });
    },
    dataURLtoFile(url, filename, mimeType) {
      return fetch(url)
        .then(function(res) {
          return res.arrayBuffer();
        })
        .then(function(buf) {
          return new File([buf], filename, { type: mimeType });
        })
        .catch((e) => {
          console.error(e);
          return e;
        });
    },
    triggerDownload(dataUrl, ext) {
      const downloadLink = document.createElement("a");
      downloadLink.href = dataUrl;
      downloadLink.download = `${this.docName}.${ext}`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    createPayload(projectId, uploadedFile) {
      return {
        _id: projectId,
        file_name: this.docName,
        is_private: this.isPrivate,
        is_template: this.isTemplate,
        is_premium_template: this.isPremiumTemplate,
        is_public_template: this.isPublicTemplate,
        folder_id: this.folderId,
        meta: {
          template_url: this.templateUrl,
        },
        preview: uploadedFile.Location,
        canvas_properties: this.canvas,
        elements: this.canvasElements,
        user_id: this.userInfo._id,
      };
    },
    saveMyProject() {
      return new Promise((resolve, reject) => {
        const catchAndReject = (e) => {
          this.$wait.end("savingProject");
          console.error(e);

          this.$message({
            message: e,
            type: "error",
          });
          reject(e);
        };

        if (!this.isAuthenticated) {
          this.showLoginBox = true;
          return reject();
        }

        let projectId = this.projectId;
        if (!this.isEditMode) {
          // user creating a new project, generate ID
          projectId = ObjectId();
        }

        this.$wait.start("savingProject");

        this.uploadPreviewToS3(projectId, this.userInfo._id)
          .then((uploadedFile) => {
            const payload = this.createPayload(projectId, uploadedFile);

            if (this.isEditMode) {
              this.$store
                .dispatch("projectsModule/updateProject", {
                  id: projectId,
                  body: payload,
                })
                .then(() => {
                  this.$wait.end("savingProject");

                  this.toggleSettingsDialog(false);
                })
                .catch((e) => {
                  catchAndReject(e);
                });
            } else {
              this.$store
                .dispatch("projectsModule/saveProject", payload)
                .then(() => {
                  this.$wait.end("savingProject");

                  this.toggleSettingsDialog(false);
                  this.$router.push(`/c/${projectId}`);
                  resolve();
                })
                .catch((e) => {
                  catchAndReject(e);
                });
            }
          })
          .catch((e) => {
            catchAndReject(e);
          });
      }).catch((e) => {
        console.error(e);
      });
    },
    uploadPreviewToS3(projectId, userId) {
      return new Promise((resolve, reject) => {
        this.downloadProject("PNG", false)
          .then((base64string) => {
            this.dataURLtoFile(base64string, `${projectId}.png`, "image/png")
              .then((file) => {
                const uploadedFile = s3upload(
                  file,
                  userId,
                  config.S3_THUMBNAIL_FOLDER
                );
                resolve(uploadedFile);
              })
              .catch((e) => {
                console.error(e);
                reject(e);
              });
          })
          .catch((e) => {
            console.error(e);

            reject(e);
          });
      });
    },

    downloadProject(command, shouldDownload) {
      return new Promise((resolve, reject) => {
        const elParent = document.querySelector(".graphproject-wrap");
        const elToExport = document.querySelector(
          ".graphproject-wrap .graphproject-canvas"
        );

        const revertOrigStyles = () => {
          elParent.style.display = "block";
          elToExport.style.margin = "0 auto";
        };

        // a small hack to center the canvas el (margin: 0 auto causes bug in dom2image)
        elParent.style.display = "flex";
        elToExport.style.margin = "0px";
        const exportOpts = {
          width: this.canvas.width,
          height: this.canvas.height,
        };
        if (command === "PNG") {
          domtoimage
            .toPng(elToExport, exportOpts)
            .then((blob) => {
              revertOrigStyles();

              if (shouldDownload) {
                saveAs(blob, `${this.docName}.png`);
                return resolve(blob);
              } else {
                return resolve(blob);
              }
            })
            .catch((e) => {
              reject(e);
            });
        } else if (command === "JPG") {
          domtoimage
            .toJpeg(elToExport, exportOpts)
            .then((dataUrl) => {
              revertOrigStyles();

              this.triggerDownload(dataUrl, "jpeg");
              return resolve(dataUrl);
            })
            .catch((e) => {
              reject(e);
            });
        } else if (command === "SVG") {
          domtoimage
            .toSvg(elToExport, exportOpts)
            .then((dataUrl) => {
              revertOrigStyles();

              this.triggerDownload(dataUrl, "svg");
              return resolve(dataUrl);
            })
            .catch((e) => {
              reject(e);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "topbar.component.scss";
</style>

<style lang="scss">
.topbar-38c2d83b .topbar-inner {
  .topbar-left {
    .project-name input {
      background-color: transparent;
      color: #fff;
      border: none;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .el-menu .resize-inputs .el-input input {
    text-align: center;
    border-color: hsla(0, 0%, 100%, 0.49);
    color: #fff;
    background-color: rgba(0, 0, 0, 0.9);
    height: 24px;
    padding: 0 8px;
  }
  .chk-public .el-checkbox__label {
    font-size: 12px;
  }
}
</style>
