<template>
  <div class="sidebar">
    <div class="sidebar-inner">
      <div class="sidebar-left1" ref="sidebarLeft1">
        <ul :class="{ show: !activeChartId, hide: activeChartId }">
          <li
            :class="{ active: selectedSectionIndex === 4 }"
            @click="selectSectionIndex(4)"
          >
            <i class="ico">
              <IcoDashboard></IcoDashboard>
            </i>
            <div class="text">TEMPLATES</div>
          </li>
          <li
            :class="{ active: selectedSectionIndex === 0 }"
            @click="selectSectionIndex(0)"
          >
            <i class="ico">
              <IcoCharts></IcoCharts>
            </i>
            <div class="text">CHARTS</div>
          </li>
          <li
            :class="{ active: selectedSectionIndex === 1 }"
            @click="selectSectionIndex(1)"
          >
            <i class="ico">
              <icoBackground></icoBackground>
            </i>
            <div class="text">BACKGROUND</div>
          </li>
          <li
            :class="{ active: selectedSectionIndex === 2 }"
            @click="selectSectionIndex(2)"
          >
            <i class="ico">
              <IcoText></IcoText>
            </i>
            <div class="text">TEXT</div>
          </li>
          <li
            :class="{ active: selectedSectionIndex === 3 }"
            @click="selectSectionIndex(3)"
          >
            <i class="ico">
              <IcoGraphics></IcoGraphics>
            </i>
            <div class="text">GRAPHICS</div>
          </li>
        </ul>
        <ul :class="{ show: activeChartId, hide: !activeChartId }">
          <li
            style="height: 35px; line-height: 48px;"
            @click="goBackToMainMenu"
          >
            <i style="font-size: 24px" class="el-icon-back"></i>
          </li>
          <li
            :class="{ active: selectedChartMenuIndex === 0 }"
            @click="selectChartMenuIndex(0)"
          >
            <i class="ico">
              <IcoData></IcoData>
            </i>
            <div class="text">DATA</div>
          </li>
          <li
            :class="{ active: selectedChartMenuIndex === 1 }"
            @click="selectChartMenuIndex(1)"
          >
            <i class="ico">
              <IcoTheme></IcoTheme>
            </i>
            <div class="text">CHART THEME</div>
          </li>
          <li
            v-if="isCartesianChart || chartType === 'polarArea'"
            :class="{ active: selectedChartMenuIndex === 2 }"
            @click="selectChartMenuIndex(2)"
          >
            <i class="ico">
              <IcoAxes></IcoAxes>
            </i>
            <div class="text">AXES / GRID</div>
          </li>
          <li
            :class="{ active: selectedChartMenuIndex === 3 }"
            @click="selectChartMenuIndex(3)"
          >
            <i class="ico">
              <IcoText></IcoText>
            </i>
            <div class="text">FONTS</div>
          </li>
          <li
            v-if="chartType !== 'bubble'"
            :class="{ active: selectedChartMenuIndex === 4, 'item-big': true }"
            @click="selectChartMenuIndex(4)"
          >
            <i class="ico">
              <IcoChartOptions></IcoChartOptions>
            </i>
            <div class="text">CHART OPTIONS</div>
          </li>

          <li
            :class="{ active: selectedChartMenuIndex === 5 }"
            @click="selectChartMenuIndex(5)"
          >
            <i class="ico">
              <IcoFill></IcoFill>
            </i>
            <div class="text">CHART FILL</div>
          </li>
          <li
            :class="{ active: selectedChartMenuIndex === 6 }"
            @click="selectChartMenuIndex(6)"
          >
            <i class="ico">
              <IcoLegend></IcoLegend>
            </i>
            <div class="text">LEGEND</div>
          </li>
          <li
            :class="{ active: selectedChartMenuIndex === 7, 'item-big': true }"
            @click="selectChartMenuIndex(7)"
            v-if="chartType !== 'rangeBar'"
          >
            <i class="ico">
              <IcoDataLabels></IcoDataLabels>
            </i>
            <div class="text">DATALABELS /MARKERS</div>
          </li>
          <li
            :class="{ active: selectedChartMenuIndex === 8, 'item-big': true }"
            @click="selectChartMenuIndex(8)"
          >
            <i class="ico">
              <IcoCode></IcoCode>
            </i>
            <div class="text">CODE</div>
          </li>
          <!-- <li
            :class="{ active: selectedChartMenuIndex === 7 }"
            @click="selectChartMenuIndex(7)"
          >
            <i class="ico">
              <IcoAnno></IcoAnno>
            </i>
            <div class="text">ANNOTATIONS</div>
          </li> -->
        </ul>
      </div>
      <div class="sidebar-left2" ref="sidebarLeft2">
        <div v-if="!activeChartId">
          <div v-if="selectedSectionIndex === 4" class="section-templates">
            <sectionTemplates></sectionTemplates>
          </div>
          <div v-if="selectedSectionIndex === 0" class="section-charts">
            <sectionCharts></sectionCharts>
          </div>

          <div v-if="selectedSectionIndex === 1" class="section-appearance">
            <sectionBackground></sectionBackground>
          </div>
          <div v-if="selectedSectionIndex === 2" class="section-text">
            <sectionText></sectionText>
          </div>
          <div v-if="selectedSectionIndex === 3" class="section-graphics">
            <sectionGraphics></sectionGraphics>
          </div>
        </div>

        <div v-else>
          <div v-if="selectedChartMenuIndex === 0" class="section-data">
            <sectionData :isCartesianChart="isCartesianChart"></sectionData>
          </div>
          <div v-if="selectedChartMenuIndex === 1" class="section-theme">
            <sectionTheme></sectionTheme>
          </div>
          <div v-if="selectedChartMenuIndex === 2" class="section-axes">
            <sectionAxes></sectionAxes>
          </div>
          <div v-if="selectedChartMenuIndex === 3" class="section-text">
            <sectionFonts></sectionFonts>
          </div>
          <div
            v-if="selectedChartMenuIndex === 4"
            class="section-chart-options"
          >
            <sectionChartOptions
              :isCartesianChart="isCartesianChart"
            ></sectionChartOptions>
          </div>
          <div v-if="selectedChartMenuIndex === 5" class="section-fillstroke">
            <sectionFillStroke></sectionFillStroke>
          </div>
          <div v-if="selectedChartMenuIndex === 6" class="section-legend">
            <sectionLegend></sectionLegend>
          </div>

          <div v-if="selectedChartMenuIndex === 7" class="section-datalabels">
            <sectionDataLabels></sectionDataLabels>
          </div>

          <div v-if="selectedChartMenuIndex === 8" class="section-code">
            <sectionCode></sectionCode>
          </div>
        </div>

        <!-- <div v-if="selectedChartMenuIndex === 7" class="section-annotations">
          <sectionAnnotations></sectionAnnotations>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import IcoData from "@/components/common/ico/datagrid.svg";
import IcoDashboard from "@/components/common/ico/templates.svg";
import IcoCharts from "@/components/common/ico/bar-chart.svg";
import IcoAxes from "@/components/common/ico/axes.svg";
import IcoGraphics from "@/components/common/ico/graphics.svg";
import IcoTheme from "@/components/common/ico/appearance.svg";
import IcoCode from "@/components/common/ico/ico-code.svg";
import icoBackground from "@/components/common/ico/background.svg";
import IcoChartOptions from "@/components/common/ico/chart-options.svg";
import IcoLegend from "@/components/common/ico/legend.svg";
import IcoFill from "@/components/common/ico/fillstroke.svg";
import IcoDataLabels from "@/components/common/ico/datalabels.svg";
import IcoText from "@/components/common/ico/text.svg";
import { mapActions } from "vuex";

// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";

export default {
  components: {
    IcoData,
    IcoCharts,
    IcoDashboard,
    IcoAxes,
    IcoGraphics,
    IcoTheme,
    icoBackground,
    IcoChartOptions,
    IcoLegend,
    IcoDataLabels,
    IcoFill,
    IcoText,
    IcoCode,
    sectionTemplates: () => import("./components/templates.component"),
    sectionCharts: () => import("./components/charts.component"),
    sectionData: () => import("./components/data.component"),
    sectionAxes: () => import("./components/axes.component"),
    sectionTheme: () => import("./components/theme.component"),
    sectionText: () => import("./components/text.component"),
    sectionFonts: () => import("./components/chart-fonts.component"),
    sectionChartOptions: () => import("./components/chartoptions.component"),
    sectionBackground: () => import("./components/background.component"),
    sectionDataLabels: () => import("./components/datalabels.component"),
    sectionFillStroke: () => import("./components/fillstroke.component"),
    sectionGraphics: () => import("./components/graphics/index.component"),
    sectionLegend: () => import("./components/legend.component"),
    sectionCode: () => import("./components/code.component"),
  },
  data() {
    return {
      selectedSectionIndex: 0,
      selectedChartMenuIndex: 0,
      lastSelectedSectionIndex: 0,
      lastSelectedChartMenuIndex: 0,
    };
  },
  computed: {
    ...mapActions("elementsModule", ["changeActiveChart"]),
  },
  mounted: function() {
    // new PerfectScrollbar(this.$refs.sidebarLeft1);
    // new PerfectScrollbar(this.$refs.sidebarLeft2);
  },
  methods: {
    selectSectionIndex(index) {
      this.selectedSectionIndex = index;
    },
    selectChartMenuIndex(index) {
      this.selectedChartMenuIndex = index;
    },
    goBackToMainMenu() {
      this.$store.dispatch("elementsModule/changeActiveChart", {
        id: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hide,
.show {
  transform-origin: bottom;
}

.show {
  opacity: 1;
  transform: translateY(0px);
  transition: 0.15s ease all;
}
.hide {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-100px);
}
</style>
