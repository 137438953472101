<template>
  <div class="custom-color-picker" ref="custom-color-picker">
    <div @click="openCustomColorPicker">
      <slot> </slot>
    </div>
    <extended-el-color-picker
      style="opacity: 0;"
      show-alpha
      @change="change"
      v-model="color"
    ></extended-el-color-picker>
  </div>
</template>

<script>
export default {
  name: "custom-color-picket",

  data: function() {
    return {
      color: "#fff",
    };
  },
  methods: {
    openCustomColorPicker: function() {
      const isPopupOpen = document.querySelector(
        ".el-color-dropdown.el-color-picker__panel"
      );

      if (isPopupOpen && isPopupOpen.style.display !== "none") {
        document.body.click();
      } else {
        this.$refs["custom-color-picker"]
          .querySelector(`.el-color-picker__trigger`)
          .click();
      }
    },
    change: function(value) {
      this.$emit("change", value);
    },
  },
};
</script>
