import { mapActions } from "vuex";

export const GRAPHICS_MIXIN = {
  computed: {
    ...mapActions("elementsModule", [
      "addImgElement",
      "addSVGElement",
      "changeActiveElement",
      "changeActiveChart",
    ]),
  },
  methods: {
    $_graphicsMixin_addImgToCanvas: function(index) {
      const imgSidebarEl = this.$refs[`img${index}`][0];

      this.$store
        .dispatch("elementsModule/addImgElement", { imgEl: imgSidebarEl })
        .then((svgEl) => {
          this.$store
            .dispatch("elementsModule/changeActiveElement", {
              id: imgSidebarEl.id,
            })
            .then((response) => {
              this.$store.dispatch("elementsModule/changeActiveChart", {
                id: null,
              });
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    $_graphicsMixin_addSVGToCanvas: function(index) {
      const sidebarSVGEl = this.$refs[`svg${index}`][0].querySelector("svg");

      this.$store
        .dispatch("elementsModule/addSVGElement", { svgEl: sidebarSVGEl })
        .then((svgEl) => {
          this.$store
            .dispatch("elementsModule/changeActiveElement", { id: svgEl.id })
            .then((response) => {
              this.$store.dispatch("elementsModule/changeActiveChart", {
                id: null,
              });
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    $_graphicsMixin_addHTMLToCanvas: function(attrs) {
      this.$store
        .dispatch("elementsModule/addHTMLElement", attrs)
        .then((htmlEl) => {
          this.$store
            .dispatch("elementsModule/changeActiveElement", { id: htmlEl.id })
            .then((response) => {
              this.$store.dispatch("elementsModule/changeActiveChart", {
                id: null,
              });
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    $_graphicsMixinCopyElementToCanvas: function(obj) {
      this.$store
        .dispatch("elementsModule/copyElement", obj)
        .then((clonedEl) => {
          this.$store
            .dispatch("elementsModule/changeActiveElement", { id: clonedEl.id })
            .then((response) => {
              this.$store.dispatch("elementsModule/changeActiveChart", {
                id: null,
              });
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
