<template>
  <div class="text-menu">
    <el-form class="form-text-menu" @submit.native.prevent ref="text-menu-form">
      <div class="menu-left">
        <el-popover
          placement="left"
          width="370"
          height="100vh"
          popper-class="popper-font-family"
          v-if="activeElement !== null"
          trigger="click"
        >
          <h3 class="mb2" style="margin-top: 51px;">Popular Fonts</h3>
          <font-picker
            :active-font="fontFamilyFromEl"
            :api-key="GOOGLE_FONTS_API_KEY"
            @change="changeFontFamilyGoogle"
            class="mr2 ml1"
          ></font-picker>

          <el-divider></el-divider>
          <h3 class="mb2 mt2">Your Uploaded Fonts</h3>
          <ul class="user-font-list">
            <li
              @click="changeFontFamilyCustom(font)"
              v-for="(font, index) in userUploadedFonts"
              :key="index"
              :style="{ fontFamily: font.font_name }"
            >
              {{ font.font_name }}
            </li>
          </ul>

          <el-select
            v-model="fontFamilyFromEl"
            :allow-create="false"
            :filterable="false"
            style="margin-right: 8px;"
            popper-class="popper-font-family"
            placeholder="Font Family"
            slot="reference"
          >
          </el-select>
        </el-popover>

        <el-select
          @change="updateFontSize"
          v-model="fontSize"
          :allow-create="true"
          :filterable="true"
          class="mr2"
          size="mini"
          placeholder="Font-size"
        >
          <el-option
            v-for="item in fontSizes"
            :label="item.label"
            :value="item.value"
            :key="item.value"
            >{{ item.value }}</el-option
          >
        </el-select>

        <div class="custom-color-picker">
          <div @click="openFontColorPicker" class="item">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="transform: scale(0.72) translateX(5px) translateY(-7px)"
            >
              <path
                d="M5.72727 16.8H15.2727L16.9909 21H21L11.9318 0H9.06818L0 21H4.00909L5.72727 16.8ZM10.5 3.78L14.07 13.3636H6.93L10.5 3.78Z"
                fill="black"
              />
            </svg>
            <div class="text-color" :style="{ backgroundColor: color }"></div>
          </div>
          <extended-el-color-picker
            size="small"
            style="opacity: 0;"
            show-alpha
            ref="fontColor"
            @change="changeTextColor"
            v-model="color"
            class="item
        "
          ></extended-el-color-picker>
        </div>

        <extended-el-color-picker
          size="small"
          show-alpha
          @change="changeTextBgColor"
          v-model="backgroundColor"
          class="item
        "
        ></extended-el-color-picker>

        <div @click="makeBold" :class="{ item: true, active: fontBold }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="black"
            width="26px"
            height="26px"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"
            />
          </svg>
        </div>

        <div @click="makeItalic" :class="{ item: true, active: fontItalic }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="black"
            width="26px"
            height="26px"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4h-8z" />
          </svg>
        </div>

        <div
          @click="makeUnderlined"
          :class="{ item: true, active: fontUnderlined }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="black"
            width="26px"
            height="24px"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z"
            />
          </svg>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ELEMENTS_MIXIN } from "@/components/common/mixins/elements.mixins";
export default {
  mixins: [ELEMENTS_MIXIN],
  data() {
    return {
      color: "",
      backgroundColor: "",
      fontFamilyFromEl: "Roboto",
      fontSize: "",
      fontBold: false,
      fontItalic: false,
      fontUnderlined: false,
      textSizes: [
        4,
        6,
        8,
        12,
        14,
        16,
        18,
        21,
        24,
        28,
        32,
        36,
        42,
        48,
        56,
        64,
        72,
        80,
        88,
        96,
        104,
      ],
    };
  },

  computed: {
    ...mapGetters("elementsModule", ["activeMoveable"]),
    ...mapGetters("brandModule", ["userUploadedFonts"]),
    ...mapActions("elementsModule", ["updateElementById"]),
    ...mapActions("brandModule", ["getUserUploadedFonts", "uploadFont"]),
    fontSizes: function() {
      return this.textSizes.map((f) => {
        return {
          label: f,
          value: f,
        };
      });
    },

    // selectedFont: function() {
    //   return this.customFontFamily
    //     ? this.customFontFamily
    //     : this.fontFamilyFromEl;
    // },
  },
  mounted: function() {
    this.existingAttrs();
  },
  watch: {
    activeElementId: function() {
      this.existingAttrs();
    },
  },
  methods: {
    existingAttrs: function() {
      this.fontFamilyFromEl = String(
        this.activeElement.style.fontFamily.replace(/[^a-zA-Z ]/g, "")
      );

      this.color = this.activeElement.style.color;
      this.backgroundColor = this.activeElement.style.backgroundColor;
      this.fontSize = parseFloat(this.activeElement.style.fontSize);
      this.fontBold = this.activeElement.style.fontWeight === "bold";
      this.fontItalic = this.activeElement.style.fontStyle === "italic";
      this.fontUnderlined =
        this.activeElement.style.textDecoration === "underline";
    },
    changeFontFamilyGoogle: function(s) {
      this.activeElement.style.fontFamily = s.family;
      this.loadProjectFonts({
        fontFamily: s.family,
      });
      this.existingAttrs();
      this.updateMoveableControlBox();
    },
    changeFontFamilyCustom(font) {
      this.activeElement.style.fontFamily = font.font_name;
      this.activeElement.dataset.fontUrl = font.font_url;

      this.loadProjectFonts({
        fontFamily: font.font_name,
        fontUrl: font.font_url,
      });
      this.existingAttrs();
      this.updateMoveableControlBox();
    },
    changeTextColor: function() {
      this.activeElement.style.color = this.color;
    },
    changeTextBgColor: function() {
      this.activeElement.style.backgroundColor = this.backgroundColor;
    },
    updateFontSize: function() {
      this.activeElement.style.fontSize = this.fontSize + "px";
      this.updateMoveableControlBox();
    },
    makeBold: function() {
      this.activeElement.style.fontWeight = !this.fontBold ? "bold" : "normal";
      this.existingAttrs();
      this.updateMoveableControlBox();
    },
    makeItalic: function() {
      this.activeElement.style.fontStyle = !this.fontItalic
        ? "italic"
        : "normal";
      this.existingAttrs();
      this.updateMoveableControlBox();
    },
    makeUnderlined: function() {
      this.activeElement.style.textDecoration = !this.fontUnderlined
        ? "underline"
        : "none";
      this.existingAttrs();
    },
    openFontColorPicker: function() {
      const isPopupOpen = document.querySelector(
        ".el-color-dropdown.el-color-picker__panel"
      );

      if (isPopupOpen && isPopupOpen.style.display !== "none") {
        document.body.click();
      } else {
        this.$el.querySelector(".el-color-picker__trigger").click();
      }
    },
    updateMoveableControlBox() {
      if (this.activeMoveable) {
        this.activeMoveable.updateRect();
      }
      this.saveHTMLElementInStore();
    },
    saveHTMLElementInStore() {
      // remove '&quot;' from font-family
      const outerHTML = this.getActiveElementOuterHTML(this.activeElement)
        .split("&quot;")
        .join("");

      this.$store.dispatch("elementsModule/updateElementById", {
        id: this.activeElementId,
        html: outerHTML,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-topbar.component.scss";

.custom-color-picker {
  width: 34px;
  border-radius: 2px;
  display: flex;

  &:hover {
    background-color: $color-element-topbar-hover-item;
  }

  .text-color {
    height: 5px;
    position: absolute;
    bottom: 5px;
    left: 4px;
    right: -4px;
    outline: 1px solid #999;
  }
}

h3 {
  font-weight: 700;
}

.user-font-list {
  min-height: calc(100vh - 210px);
  overflow-y: scroll;
  list-style: none;
  padding-left: 5px;
  li {
    cursor: pointer;
    font-size: 18px;
    line-height: 40px;
    padding-left: 5px;

    &:hover {
      background: #dddddd;
    }
  }
}
</style>
