<template>
  <div class="svg-menu">
    <el-form @submit.native.prevent ref="svg-menu-form">
      <extended-el-color-picker
        size="small"
        show-alpha
        @change="changeSVGColor"
        v-model="color"
      ></extended-el-color-picker>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ELEMENTS_MIXIN } from "@/components/common/mixins/elements.mixins";

export default {
  mixins: [ELEMENTS_MIXIN],
  data() {
    return {
      color: "",
    };
  },

  props: {},
  mounted: function() {
    this.getExistingAttrs();
  },
  computed: {
    ...mapActions("elementsModule", ["updateElementById"]),
  },
  watch: {
    activeElementId: function() {
      this.getExistingAttrs();
    },
  },
  methods: {
    changeSVGColor: function() {
      const path = this.activeElement.querySelectorAll(
        "path, circle, rect, polygon"
      );
      path.forEach((p) => {
        p.setAttribute("fill", this.color);
      });
      this.saveElementInStore();
    },

    getExistingAttrs: function() {
      const paths = this.activeElement.querySelectorAll(
        "path, circle, rect, polygon"
      );
      if (paths) {
        paths.forEach((p) => {
          const fill = p.getAttribute("fill") || p.style.fill;
          this.color = fill !== "none" ? fill : "";
        });
      }
    },
    saveElementInStore() {
      this.$store.dispatch("elementsModule/updateElementById", {
        id: this.activeElementId,
        html: this.getActiveElementOuterHTML(this.activeElement),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-topbar.component.scss";

.color-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>
