<template>
  <div class="shape-menu">
    <el-form @submit.native.prevent ref="svg-menu-form">
      <div class="item item-SjoWemk6ok">
        <custom-color-picker @change="changeBgColor">
          <img
            :src="`${publicPath}assets/icons/ico-fill.png`"
            style="height: 21px; position: relative; left: 3px; margin-right: 4px;"
            alt=""
          />
        </custom-color-picker>
      </div>

      <div class="item item-SjoWemk6ok">
        <custom-color-picker @change="changeBorderColor">
          <div
            class="border-color"
            :style="{
              borderColor: borderColor,
              position: 'relative',
              left: '-1px',
            }"
          ></div>
        </custom-color-picker>
      </div>

      <el-popover
        placement="bottom"
        width="300"
        class="item item-menu-text-button"
        trigger="click"
      >
        <div class="block">
          <span class="demonstration">Border Width</span>
          <el-slider
            @change="changeBorderWidth"
            v-model="borderWidth"
            class="ml2"
            :min="0"
            :max="100"
            :step="1"
            show-input
          ></el-slider>
        </div>

        <div class="block">
          <span class="demonstration">Border Radius</span>
          <el-slider
            @change="changeBorderRadius"
            v-model="borderRadius"
            class="ml2"
            :min="0"
            :max="200"
            :step="1"
            show-input
          ></el-slider>
        </div>

        <div class="block mt3">
          <span
            style="margin-top: 3px; display: inline-block;"
            class="demonstration"
            >Border Style</span
          >
          <el-select
            @change="updateBorderStyle"
            v-model="borderStyle"
            :allow-create="false"
            :filterable="false"
            placeholder="Border Style"
            size="mini"
            class="float-right"
          >
            <el-option
              v-for="item in borderStyles"
              :label="item.label"
              :value="item.value"
              :key="item.value"
              >{{ item.value }}</el-option
            >
          </el-select>
        </div>
        <el-button class="btn" type="text" slot="reference">
          Border
        </el-button>
      </el-popover>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ELEMENTS_MIXIN } from "@/components/common/mixins/elements.mixins";
import customColorPicker from "@/components/common/custom-color-picker/custom-color-picker.component";

export default {
  mixins: [ELEMENTS_MIXIN],
  components: {
    "custom-color-picker": customColorPicker,
  },
  data() {
    return {
      borderColor: "",
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 0,
      bStyles: [
        "dotted",
        "dashed",
        "solid",
        "double",
        "groove",
        "ridge",
        "inset",
        "outset",
      ],
      backgroundColor: "",
    };
  },
  computed: {
    ...mapGetters("elementsModule", []),
    ...mapActions("elementsModule", ["updateElementById"]),
    borderStyles: function() {
      return this.bStyles.map((b) => {
        return {
          label: b,
          value: b,
        };
      });
    },
  },
  mounted: function() {
    this.getExistingAttrs();
  },
  watch: {
    activeElementId: function() {
      this.getExistingAttrs();
    },
  },
  methods: {
    changeBorderColor: function(borderColor) {
      this.activeElement.style.borderColor = borderColor;
      this.borderColor = borderColor;
      this.saveElementInStore();
    },
    changeBorderWidth: function() {
      this.activeElement.style.borderWidth = this.borderWidth + "px";
      this.saveElementInStore();
    },
    changeBorderRadius: function() {
      this.activeElement.style.borderRadius = this.borderRadius + "px";
      this.saveElementInStore();
    },
    updateBorderStyle: function() {
      this.activeElement.style.borderStyle = this.borderStyle;
      this.saveElementInStore();
    },
    changeBgColor: function(bgcolor) {
      this.activeElement.style.backgroundColor = bgcolor;
      this.backgroundColor = bgcolor;
      this.saveElementInStore();
    },
    getExistingAttrs: function() {
      const borderColor = this.activeElement.style.borderColor;
      this.borderColor = borderColor !== "none" ? borderColor : "";
      this.borderWidth = parseInt(this.activeElement.style.borderWidth);
      this.borderStyle = this.activeElement.style.borderStyle;
      this.borderRadius = parseInt(this.activeElement.style.borderRadius);

      this.backgroundColor = this.activeElement.style.backgroundColor;
    },

    saveElementInStore() {
      this.$store.dispatch("elementsModule/updateElementById", {
        id: this.activeElementId,
        html: this.getActiveElementOuterHTML(this.activeElement),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-topbar.component.scss";
.border-color {
  border: 4px double #454545;
  width: 22px;
  height: 22px;
  margin-left: 1px;
  border-radius: 4px;
}
</style>

<style lang="scss">
.item-SjoWemk6ok {
  .custom-color-picker,
  .custom-color-picker > div:first-child {
    display: flex;
    height: 34px;
    width: 34px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}
</style>
