<template>
  <div class="element-topbar">
    <div class="element-topbar-inner">
      <el-menu class="el-menu" mode="horizontal">
        <div class="menu-left">
          <svgMenu v-if="activeElementType === 'svg'"></svgMenu>
          <textMenu v-if="activeElementType === 'section'"></textMenu>
          <shapeMenu v-if="activeElementType === 'div'"></shapeMenu>
        </div>
        <div
          :class="{
            'menu-right': true,
            'bg-menu': activeElementType === 'article',
          }"
          v-if="activeElementId !== null || activeChartId !== null"
        >
          <el-button
            type="text"
            class="item el-ico-btn"
            @click="copyElement()"
            title="Copy"
            v-if="
              (activeChartId !== null || activeElement !== null) &&
                activeElementType !== 'article'
            "
          >
            <img
              style="transform: scale(0.85)"
              :src="`${publicPath}assets/icons/ico-copy-content.svg`"
              alt=""
            />
          </el-button>
          <el-button
            type="text"
            class="item el-ico-btn"
            @click="moveLayer('up')"
            title="Move Up"
            v-if="activeElementType !== 'article'"
          >
            <img
              style="transform: scale(1.5) translate(0px, 1px)"
              :src="`${publicPath}assets/icons/layer-upv1.svg`"
              alt=""
            />
          </el-button>
          <el-button
            type="text"
            class="item el-ico-btn"
            style="margin-left: 7px; margin-right: 4px;"
            title="Move Down"
            v-if="activeElementType !== 'article'"
            @click="moveLayer('down')"
          >
            <img
              style="transform: scale(1.5) translate(0px, 1px);"
              :src="`${publicPath}assets/icons/layer-downv1.svg`"
              alt=""
            />
          </el-button>
          <el-popover
            placement="bottom"
            title="Filter Effects"
            width="300"
            trigger="click"
            class="item"
            v-if="activeElement !== null"
          >
            <div class="block">
              <span class="demonstration">Brightness</span>
              <el-slider
                @change="effect"
                v-model="brightness"
                :min="0"
                :max="2"
                :step="0.1"
              ></el-slider>
            </div>
            <div class="block">
              <span class="demonstration">Contrast</span>
              <el-slider
                @change="effect"
                v-model="contrast"
                :min="0"
                :max="1"
                :step="0.1"
              ></el-slider>
            </div>
            <div class="block">
              <span class="demonstration">Saturate</span>
              <el-slider
                @change="effect"
                v-model="saturate"
                :min="0"
                :max="2"
                :step="0.1"
              ></el-slider>
            </div>
            <div class="block">
              <span class="demonstration">Blur</span>
              <el-slider
                @change="effect"
                v-model="blur"
                :min="0"
                :max="10"
                :step="0.1"
              ></el-slider>
            </div>
            <el-button class="item" type="text" slot="reference">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M19 10v9H4.98V5h9V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2zm-2.94-2.06L17 10l.94-2.06L20 7l-2.06-.94L17 4l-.94 2.06L14 7zM12 8l-1.25 2.75L8 12l2.75 1.25L12 16l1.25-2.75L16 12l-2.75-1.25z"
                />
              </svg>
            </el-button>
          </el-popover>

          <el-popover
            placement="bottom"
            title="Shadow"
            width="250"
            class="item"
            v-if="activeElement !== null && activeElementType !== 'article'"
            trigger="click"
          >
            <el-checkbox
              @change="enableShadow"
              border
              class="text-center d-block"
              v-model="shadowEnabled"
              >Enable Drop-shadow</el-checkbox
            >
            <div v-if="shadowEnabled">
              <div class="block mt2">
                <div class="d-flex align-center mb3">
                  <span> Choose Color</span>
                  <extended-el-color-picker
                    size="small"
                    ref="fontColor"
                    class="ml2"
                    @change="effect"
                    v-model="dropShadow[0]"
                  ></extended-el-color-picker>
                </div>
              </div>
              <div class="block">
                <span class="demonstration">Left Offset</span>
                <el-slider
                  @change="effect"
                  v-model="dropShadow[1]"
                  :min="-50"
                  :max="50"
                  :step="1"
                ></el-slider>
              </div>
              <div class="block">
                <span class="demonstration">Top Offset</span>
                <el-slider
                  @change="effect"
                  v-model="dropShadow[2]"
                  :min="-50"
                  :max="50"
                  :step="1"
                ></el-slider>
              </div>
              <div class="block">
                <span class="demonstration">Blur</span>
                <el-slider
                  @change="effect"
                  v-model="dropShadow[3]"
                  :min="0"
                  :max="20"
                  :step="1"
                ></el-slider>
              </div>
            </div>

            <el-button type="text" slot="reference">
              <svg
                style="transform: scale(1.02)"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.94-.49-7-3.85-7-7.93s3.05-7.44 7-7.93v15.86zm2-15.86c1.03.13 2 .45 2.87.93H13v-.93zM13 7h5.24c.25.31.48.65.68 1H13V7zm0 3h6.74c.08.33.15.66.19 1H13v-1zm0 9.93V19h2.87c-.87.48-1.84.8-2.87.93zM18.24 17H13v-1h5.92c-.2.35-.43.69-.68 1zm1.5-3H13v-1h6.93c-.04.34-.11.67-.19 1z"
                />
              </svg>
            </el-button>
          </el-popover>

          <el-popover
            placement="bottom"
            title="Transparency"
            v-if="activeElement !== null"
            width="200"
            class="item"
            trigger="click"
          >
            <div>
              <el-slider
                @change="changeTransparency"
                v-model="transparency"
                :min="0"
                :max="1"
                :step="0.1"
              ></el-slider>
            </div>
            <el-button type="text" class="btn-transparency" slot="reference">
              <img
                :src="`${publicPath}assets/icons/chess-board-solid.svg`"
                alt=""
              />
            </el-button>
          </el-popover>
          <div class="item" @click="deleteElement">
            <img
              :src="`${publicPath}assets/icons/bin.png`"
              style="height: 20px; width: 20px;"
              alt=""
            />
          </div>
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { generateID } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import svgMenu from "./svg-menu.component";
import textMenu from "./text-menu.component";
import shapeMenu from "./shape-menu.component";
import { getCSSFilterValues } from "@/components/common/utils";
import { GRAPHICS_MIXIN } from "../sidebar/components/graphics/graphics.mixins";

export default {
  mixins: [GRAPHICS_MIXIN],
  data() {
    return {
      transparency: 1,
      blur: 0,
      brightness: 1,
      contrast: 1,
      saturate: 1,
      dropShadow: ["#888888", 1, 1, 2],
      shadowEnabled: false,
    };
  },
  components: {
    svgMenu,
    textMenu,
    shapeMenu,
  },

  computed: {
    ...mapActions("elementsModule", [
      "moveElementLayer",
      "changeActiveElement",
      "changeActiveChart",
      "copyChart",
    ]),
    ...mapGetters("elementsModule", ["canvasElements"]),
  },
  mounted: function() {
    this.getExistingAttrs();
  },
  watch: {
    activeElementId: function() {
      this.getExistingAttrs();
    },
  },
  methods: {
    changeTransparency: function() {
      this.activeElement.style.opacity = this.transparency;
    },
    enableShadow: function() {
      this.effect();
    },
    getExistingAttrs() {
      if (this.activeElement) {
        const opa = parseFloat(this.activeElement.style.opacity);
        if (!isNaN(opa)) {
          this.transparency = opa;
        }
        if (this.activeElement.style.filter) {
          const CSSFilterValues = getCSSFilterValues(
            this.activeElement.style.filter
          );

          this.shadowEnabled = CSSFilterValues.dropShadow[0] !== "transparent";
          this.setEffectValues(
            CSSFilterValues.brightness,
            CSSFilterValues.contrast,
            CSSFilterValues.saturate,
            CSSFilterValues.blur,
            CSSFilterValues.dropShadow
          );
        } else {
          this.shadowEnabled = false;
          this.setEffectValues(1, 1, 1, 0, ["#888888", 1, 1, 2]);
        }
      }
    },
    effect() {
      const b = this.brightness;
      const c = this.contrast;
      const bl = this.blur;
      const s = this.saturate;
      const d = this.dropShadow;

      let dColor = d[0];

      if (!this.shadowEnabled) {
        dColor = "transparent";
      }

      this.activeElement.style.filter = `brightness(${b}) contrast(${c}) blur(${bl}px) saturate(${s}) drop-shadow(${d[1]}px ${d[2]}px ${d[3]}px ${dColor})`;
    },
    setEffectValues(brightness, contrast, saturate, blur, dropShadow) {
      this.brightness = brightness;
      this.contrast = contrast;
      this.saturate = saturate;
      this.blur = blur;
      this.dropShadow = dropShadow;
    },
    moveLayer: function(direction) {
      const id = this.$parent.getID();
      const prevActiveElementType = this.activeElementId ? "element" : "chart";
      if (!id) return;

      this.$store
        .dispatch("elementsModule/changeActiveChart", { id: null })
        .then(() => {
          this.$store.dispatch("elementsModule/changeActiveElement", {
            id: null,
          });
        })
        .catch((e) => {
          console.error(e);
        });

      this.$store
        .dispatch("elementsModule/moveElementLayer", {
          id: id,
          direction,
        })
        .then((response) => {
          if (prevActiveElementType === "element") {
            this.$store.dispatch("elementsModule/changeActiveElement", {
              id,
            });
          } else if (prevActiveElementType === "chart") {
            this.$store.dispatch("elementsModule/changeActiveChart", {
              id,
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    copyElement: function() {
      if (this.activeElementId) {
        const cloned = this.activeElement.cloneNode(true);
        cloned.id = generateID(5);

        this.$_graphicsMixinCopyElementToCanvas({
          id: cloned.id,
          elType: cloned.nodeName.toLowerCase(),
          html: cloned.outerHTML,
        });
      } else if (this.activeChartId) {
        const cloned = this.canvasElements.find((el) => {
          return el.id === this.activeChartId;
        });

        this.$store
          .dispatch("elementsModule/copyChart", {
            position: {
              x: cloned.position.x + 20,
              y: cloned.position.y + 20,
            },
            src: cloned.src,
          })
          .then((id) => {
            window.setTimeout(() => {
              document.querySelector(".sidebar").scrollTop = 0;

              this.$store
                .dispatch("elementsModule/changeActiveChart", { id })
                .then((response) => {
                  this.$store.dispatch("elementsModule/changeActiveElement", {
                    id: null,
                  });
                })
                .catch((e) => {
                  console.error(e);
                });
            }, 300);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    deleteElement: function() {
      this.$parent.deleteElement();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-topbar.component.scss";
</style>
