<template>
  <el-dialog
    class="login-popup-b6f9642c"
    width="30%"
    :visible.sync="loginBoxShown"
    :append-to-body="true"
    :before-close="beforeClose"
    :destroy-on-close="true"
    :center="true"
  >
    <login @afterLogin="closeAfterLogin" />
  </el-dialog>
</template>

<script>
import login from "../login/login.component.vue";
export default {
  data() {
    return {
      loginBoxShown: false,
    };
  },

  components: {
    login,
  },
  mounted: function() {
    this.loginBoxShown = true;
  },
  methods: {
    beforeClose: function() {
      this.$emit("onCloseLogin");
    },
    closeAfterLogin: function() {
      this.$emit("saveAfterLogin");
      this.$emit("onCloseLogin");
    },
  },
};
</script>

<style lang="scss">
.login-popup-b6f9642c {
  .el-dialog__header {
    padding: 0;
  }
}
</style>
